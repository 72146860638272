/*
  @page Batch list
*/

//Import require libraries
import React, { useState, useEffect, useMemo, useRef } from "react";
import Pagination from '@mui/material/Pagination';
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink, CSVDownload } from 'react-csv';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import RemoveRedEyeRoundedIcon from '@material-ui/icons/RemoveRedEyeRounded';
import Helper from "../../util/Helper";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';


/*
  @const Page styles
*/
const styles = {
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#ffa726"
    }
  },
  root: {
    '& .Mui-selected': {
      backgroundColor: 'black',
      color: '#ffa726',
    }
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  DatePicker: {
    padding: "5px",
    borderColor: "#dadada"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  datePickerTextBox: {
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  }
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);

/*
  @function Batch list page logic
*/
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [openSendReceipt, setOpenSendReceipt] = useState(false);
  const [sendEmail, setSendEmail] = useState("");
  const [ecrRefNo, setEcrRefNo] = useState(0);
  const [amt, setAmt] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPages, setCurrentPages] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(15);
  const [viewReportDailog, setViewReportDailog] = useState(false);
  const [creditAmt, setCreditAmt] = useState(0);
  const [creditTipAmt, setCreditTipAmt] = useState(0);
  const [creditNonCashAdjAmt, setCreditNonCashAdjAmt] = useState(0);
  const [debitNonCashAdjAmt, setDebitNonCashAdjAmt] = useState(0);
  const [creditVoidCnt, setCreditVoidCnt] = useState(0);
  const [creditVoidAmt, setCreditVoidAmt] = useState(0);
  const [creditReturnCnt, setCreditReturnCnt] = useState(0);
  const [creditReturnAmt, setCreditReturnAmt] = useState(0);
  const [creditSubTotal, setCreditSubTotal] = useState(0);
  const [creditTotal, setCreditTotal] = useState(0);
  const [debitAmt, setDebitAmt] = useState(0);
  const [debitTipAmt, setDebitTipAmt] = useState(0);
  const [debitVoidCnt, setDebitVoidCnt] = useState(0);
  const [debitVoidAmt, setDebitVoidAmt] = useState(0);
  const [debitReturnCnt, setDebitReturnCnt] = useState(0);
  const [debitReturnAmt, setDebitReturnAmt] = useState(0);
  const [debitSubTotal, setDebitSubTotal] = useState(0);
  const [debitTotal, setDebitTotal] = useState(0);
  const [creditVisaCount, setCreditVisaCount] = useState(0);
  const [creditVisaAmt, setCreditVisaAmt] = useState(0);
  const [creditMasterCardCount, setCreditMasterCardCount] = useState(0);
  const [creditMasterCardAmt, setCreditMasterCardAmt] = useState(0);
  const [creditDiscoverCount, setCreditDiscoverCount] = useState(0);
  const [creditDiscoverAmt, setCreditDiscoverAmt] = useState(0);
  const [creditAECount, setCreditAECount] = useState(0);
  const [creditAEAmt, setCreditAEAmt] = useState(0);
  const [creditOtherCount, setCreditOtherCount] = useState(0);
  const [creditOtherAmt, setCreditOtherAmt] = useState(0);
  const [grandTotalAmt, setGrandTotalAmt] = useState(0);
  const [totalTipAmt, setTotalTipAmt] = useState(0);
  const [debitVisaCount, setDebitVisaCount] = useState(0);
  const [debitVisaAmt, setDebitVisaAmt] = useState(0);
  const [debitMasterCardCount, setDebitMasterCardCount] = useState(0);
  const [debitMasterCardAmt, setDebitMasterCardAmt] = useState(0);
  const [debitDiscoverCount, setDebitDiscoverCount] = useState(0);
  const [debitDiscoverAmt, setDebitDiscoverAmt] = useState(0);
  const [debitAECount, setDebitAECount] = useState(0);
  const [debitAEAmt, setDebitAEAmt] = useState(0);
  const [debitOtherCount, setDebitOtherCount] = useState(0);
  const [debitOtherAmt, setDebitOtherAmt] = useState(0);
  const [ebtSalesAmt, setEbtSalesAmt] = useState(0);
  const [ebtSalesCnt, setEbtSalesCnt] = useState(0);
  const [ebtVoidsAmt, setEbtVoidsAmt] = useState(0);
  const [ebtVoidsCnt, setEbtVoidsCnt] = useState(0);
  const [ebtReturnsAmt, setEbtReturnsAmt] = useState(0);
  const [ebtReturnsCnt, setEbtReturnsCnt] = useState(0);
  const [ebtTotal, setEbtTotal] = useState(0);
  const [transData, setTransData] = useState(false);
  const [batchTransDailog, setBatchTransDailog] = useState(false);
  const [currentBatchTransDailog, setCurrentBatchTransDailog] = useState(false);
  const [exportButtonLabel, setExportButtonLabel] = useState("Export");
  const [currentBatchTransData, setCurrentBatchTransData] = useState();
  const [accessPage, setAccessPage] = useState(1);  

  const addclass=()=>{
    document.querySelector(".ps--active-y").classList.remove('newTop');
    document.querySelector(".ps--active-y").classList.add('newTop');
    setTimeout(function() {
      document.querySelector(".ps--active-y").classList.remove('newTop');
    }, 2000);
  } 

  const clearAll = () => {
    setCreditAmt(0);
    setCreditTipAmt(0);
    setCreditVoidCnt(0);
    setCreditVoidAmt(0);
    setCreditReturnCnt(0);
    setCreditReturnAmt(0);
    setCreditSubTotal(0);
    setCreditTotal(0);
    setDebitAmt(0);
    setDebitTipAmt(0);
    setDebitVoidCnt(0);
    setDebitVoidAmt(0);
    setDebitReturnCnt(0);
    setDebitReturnAmt(0);
    setDebitSubTotal(0);
    setDebitTotal(0);
    setCreditVisaCount(0);
    setCreditVisaAmt(0);
    setCreditMasterCardCount(0);
    setCreditMasterCardAmt(0);
    setCreditDiscoverCount(0);
    setCreditDiscoverAmt(0);
    setCreditAECount(0);
    setCreditAEAmt(0);
    setCreditOtherCount(0);
    setCreditOtherAmt(0);
    setGrandTotalAmt(0);
    setTotalTipAmt(0);
    setDebitVisaCount(0);
    setDebitVisaAmt(0);
    setDebitMasterCardCount(0);
    setDebitMasterCardAmt(0);
    setDebitDiscoverCount(0);
    setDebitDiscoverAmt(0);
    setDebitAECount(0);
    setDebitAEAmt(0);
    setDebitOtherCount(0);
    setDebitOtherAmt(0);
    setEbtSalesAmt(0);
    setEbtSalesCnt(0);
    setEbtVoidsAmt(0);
    setEbtVoidsCnt(0);
    setEbtReturnsAmt(0);
    setEbtReturnsCnt(0);
    setEbtTotal(0);
  }
  useEffect(() => {
    var isLocationActive = localStorage.getItem('is_active');
     if(isLocationActive === "0")
    {
        window.location.href = '/in_active';
       return;
    }    const permissonData = JSON.parse(localStorage.getItem('permissonData'));   
    
    if(permissonData)
    {
      if(permissonData.access_batch_reports === 0 || permissonData.access_to_online_dashboard === 0)
      {
        setAccessPage(0);
        return;
      }
    }

    const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
    getTransList(data);
  }, []);


  /*
    @function View batch report
  */
  const handleViewBatchReport = (e, eachBatch) => {
    e.preventDefault();
    clearAll();
    const cardTypeData = JSON.parse(eachBatch.extra_one);

    if (eachBatch.extra_one === null || eachBatch.extra_one === "") //Check data was null or blank
    {
      setCreditMasterCardCount(0);
      setCreditVisaCount(0);
      setCreditDiscoverCount(0);
      setCreditAECount(0);
      setCreditOtherCount(0);
      setDebitMasterCardCount(0);
      setDebitVisaCount(0);
      setDebitDiscoverCount(0);
      setDebitAECount(0);
      setDebitOtherCount(0);
    }
    else {
      Object.values(cardTypeData.credit).map((value) => { //If transaction using credit card  
        if (value.card_type === "MASTER CARD" || value.card_type === "MASTERCARD") //Check card type was mastercard
        {
          setCreditMasterCardCount(value.count);
          setCreditMasterCardAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
        else if (value.card_type === "VISA") //Check card type was visa
        {
          setCreditVisaCount(value.count);
          setCreditVisaAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
        else if (value.card_type === "DISCOVER") //Check card type was discover
        {
          setCreditDiscoverCount(value.count);
          setCreditDiscoverAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
        else if (value.card_type === "AMERICAN EXPRESS" || value.card_type === "AMERICANEXPRESS" || value.card_type === "AMEX") //Check card type was american express
        {
          setCreditAECount(value.count);
          setCreditAEAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
        else {
          setCreditOtherCount(value.count);
          setCreditOtherAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
      });

      Object.values(cardTypeData.debit).map((value) => {  //If transaction using debit card      
        if (value.card_type === "MASTER CARD" || value.card_type === "MASTERCARD") //Check card type was mastercard
        {
          setDebitMasterCardCount(value.count);
          setDebitMasterCardAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
        else if (value.card_type === "VISA") //Check card type was visa
        {
          setDebitVisaCount(value.count);
          setDebitVisaAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
        else if (value.card_type === "DISCOVER") //Check card type was discover
        {
          setDebitDiscoverCount(value.count);
          setDebitDiscoverAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
        else if (value.card_type === "AMERICAN EXPRESS" || value.card_type === "AMERICANEXPRESS" || value.card_type === "AMEX") //Check card type was american express
        {
          setDebitAECount(value.count);
          setDebitAEAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
        else {
          setDebitOtherCount(value.count);
          setDebitOtherAmt(parseFloat(value.total) - parseFloat(value.tip_total) - parseFloat((value.non_cash_total)?value.non_cash_total:0.00));
        }
      });
    }

    setViewReportDailog(true);
    setCreditAmt(eachBatch.credit_amt - eachBatch.credit_non_cash_amt);
    setCreditTipAmt(eachBatch.credit_tip_amt);
    setCreditNonCashAdjAmt(eachBatch.credit_non_cash_amt);
    setDebitNonCashAdjAmt(eachBatch.debit_non_cash_amt);
    setCreditVoidCnt(eachBatch.credit_void_cnt);
    setCreditVoidAmt(eachBatch.credit_void_amt);
    setCreditReturnCnt(eachBatch.credit_return_cnt);
    setCreditReturnAmt(eachBatch.credit_return_amt);
    const creditsubTotalVal = parseFloat(eachBatch.credit_amt) - parseFloat(eachBatch.credit_return_amt) - parseFloat(eachBatch.credit_non_cash_amt);
    setCreditSubTotal(creditsubTotalVal);
    const creditTotalVal = creditsubTotalVal + parseFloat(eachBatch.credit_tip_amt) + parseFloat(eachBatch.credit_non_cash_amt);
    setCreditTotal(creditTotalVal);
    setDebitAmt(eachBatch.debit_amt - eachBatch.debit_non_cash_amt);
    setDebitTipAmt(eachBatch.debit_tip_amt);
    setDebitVoidCnt(eachBatch.debit_void_cnt);
    setDebitVoidAmt(eachBatch.debit_void_amt);
    setDebitReturnCnt(eachBatch.debit_return_cnt);
    setDebitReturnAmt(eachBatch.debit_return_amt);
    const debitsubTotalVal = parseFloat(eachBatch.debit_amt) - parseFloat(eachBatch.debit_return_amt) - parseFloat(eachBatch.debit_non_cash_amt);
    setDebitSubTotal(debitsubTotalVal);
    const debitTotalVal = parseFloat(debitsubTotalVal) + parseFloat(eachBatch.debit_tip_amt) + parseFloat(eachBatch.debit_non_cash_amt);
    setDebitTotal(debitTotalVal);
    const grandTotalAmtValue = creditTotalVal + debitTotalVal;
    setGrandTotalAmt(grandTotalAmtValue);
    const totalTipAmtValue = parseFloat(eachBatch.credit_tip_amt) + parseFloat(eachBatch.debit_tip_amt);
    setTotalTipAmt(totalTipAmtValue);
    setEbtSalesAmt(eachBatch.ebt_amount);
    setEbtSalesCnt(eachBatch.ebt_count);
    setEbtVoidsCnt(0);
    setEbtReturnsCnt(0);
    const ebtTotalValue = parseFloat(ebtSalesAmt) + parseFloat(ebtVoidsAmt) + parseFloat(ebtReturnsAmt);
    setEbtTotal(ebtTotalValue);

  }

  /*
    @function Get every batch transaction list
  */
  const getBatchTrans = (e, batchId, serial_number) => {
    setBatchTransDailog(true);
    e.preventDefault();
    const data = { batch_id: batchId, serial_number: serial_number };
    const headers =
    {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_batch_trans', data, {
      headers: headers
    }).then(response => {
      var data = [];
      response.data.transData.forEach((eachTrans) => {
        let transTime = (eachTrans.device_created_at) ? moment(new Date(eachTrans.device_created_at.replace(" ", "T"))).format('MM/DD/YYYY HH:mm') : "";
        data.push([transTime, eachTrans.ecrref_num, <center>{(eachTrans.edc_type) ? eachTrans.edc_type : eachTrans.payment_method}</center>, <center>{eachTrans.auth_code}</center>, <div style={{ textAlign: 'center' }}>  {(eachTrans.transaction_type == "RETURN")?"-":""}{(eachTrans.ecrref_num) ? "$" : ""}  {(eachTrans.amount) ? eachTrans.amount: ""}</div>]);
      });
      setTransData(data);
    }).catch(error => {     
      return error;
    });
  }
  
  const closeBatch = (e,serial_number) =>
  {
    e.preventDefault();
    const data = {};
    const headers =
    {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    data.batch_number = "0";
    data.cash_amt = "0";
    data.cash_cnt = "0";
    data.credit_amt = "0";
    data.credit_cnt = "0";
    data.debit_amt = "0";
    data.debit_cnt = "0";
    data.ebt_amount = "0";
    data.ebt_count = "0";
    data.time_stamp = "";
    data.serial_nuber = serial_number;
    data.batch_res = "manually batch close";
    data.offline_id = "";


    axios.post(window.apiUrl + 'save_batch', data, {
      headers: headers
    }).then(response => {
      
      
    }).catch(error => {     
      return error;
    });

  }
  /*
    @function Batch transaction list
  */
  const getTransList = (body) => {
    const data = body;
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_batch', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      const exportArr = [];
      exportArr.push(["Date", "Time", "Total", "Total Count", "Total Credit Amount", "Total Credit Transactions", "Total Debit Amount", "Total Debit Transactions", "Device"]);
      setTotalPages(response.data.totalPage);
      setCurrentPages(response.data.current_page);
      response.data.open_batch_data.forEach((eachOpenBatch) => {
        userArr.push([<div style={{ textAlign: "center" }}>{moment(new Date(eachOpenBatch.device_created_at.replace(" ", "T"))).format('MM/DD/YYYY')}</div>, <div style={{ textAlign: "center" }}>{moment(new Date(eachOpenBatch.device_created_at.replace(" ", "T"))).format('HH:mm A')}</div>, <div style={{ float: "right" }}>${eachOpenBatch.total_amt}</div>, <div style={{ textAlign: "center" }}>{eachOpenBatch.serial_number}</div>, <center>-</center>, <Button style={{ width:'100px', color: 'white', background: '#ffa726' }} onClick={(e) => { getBatchTrans(e, 0, eachOpenBatch.serial_number) }}>Open</Button>]);
      });
      response.data.batchData.forEach((eachBatch) => {
        var cash_amt = "";
        var credit_amt = "";
        var debit_amt = "";
        cash_amt = Helper.viewCurrencyFormat(parseFloat(eachBatch.cash_amt));
        credit_amt = Helper.viewCurrencyFormat(parseFloat(eachBatch.credit_amt));
        debit_amt = Helper.viewCurrencyFormat(parseFloat(eachBatch.debit_amt));
        var total = parseFloat(eachBatch.credit_amt) + parseFloat(eachBatch.credit_tip_amt) + parseFloat(eachBatch.debit_amt) + parseFloat(eachBatch.debit_tip_amt) + parseFloat(eachBatch.ebt_amount) - parseFloat(eachBatch.credit_return_amt) - parseFloat(eachBatch.debit_return_amt);
        total = Helper.viewCurrencyFormat(parseFloat(total));
        var t = new Date(eachBatch.created_at);
        var TotalCnt = parseInt(eachBatch.credit_cnt) + parseInt(eachBatch.debit_cnt);

        userArr.push([<div style={{ textAlign: "center" }}>{eachBatch.device_date}</div>, <div style={{ textAlign: "center" }}>{eachBatch.device_time}</div>, <div style={{ float: "right" }}>{total}</div>, <div style={{ textAlign: "center" }}>{eachBatch.serial_nuber}</div>, <div style={{ textAlign: "center" }}><a target="_blank" onClick={(e) => { handleViewBatchReport(e, eachBatch) }}><RemoveRedEyeRoundedIcon style={{ color: '#ffa726' }}></RemoveRedEyeRoundedIcon></a></div>, <Button id="btnGreenAdd" style={{ width:'100px',color: 'white', background: 'green' }} onClick={(e) => { getBatchTrans(e, eachBatch.id, eachBatch.serial_nuber) }}>Closed</Button>]);
        exportArr.push([eachBatch.device_date, eachBatch.device_time, total, TotalCnt, credit_amt, eachBatch.credit_cnt, debit_amt, eachBatch.debit_cnt, eachBatch.serial_nuber]);

      });
      setTableData(userArr);
      setCsvData(exportArr);

    }).catch(error => {     
      return error;
    });
  }
  
  
  const handledialogoutclick = () => {
    setViewReportDailog(false)
  }
  /*
    @function For sent batch report in Email
  */
  const sendEmailReceipt = () => {

    const data = {
      "receipt_email_address": sendEmail,
      "ecr_ref_no": ecrRefNo,
      "total_amount": amt,
    };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'send_receipt_email', data, {
      headers: headers
    }).then(response => {
      setOpenSendReceipt(false);
      setSendEmail("");
      setEcrRefNo(0);
      setAmt(0);
      if (response.data.msg === "success") {
        setSuccess(true);
        setSuccessMsg("Receipt Sent Successfully!");

      }
      else {
        setError(true);
        setErrorMsg("Some Error Occure,Receipt Not Sent!!");

      }

    }).catch(error => {     
      return error;
    });
  }
  
  
  /*
    @function search data
  */
  const handleSearch = () => {
    setTableData(false);
    var et = endDate;
    if (endDate === null || endDate === "" || endDate === undefined) { //Check end date was null,empty or undefined
      et = new Date().toISOString().slice(0, 16);
      setEndDate(et);
    } else {
      var eDate = new Date(endDate);
    }
    const data = { 'startDate': startDate.replace("T", " ") + ":00", 'endDate': et.replace("T", " ") + ":00", 'current_page': 1, 'rowPerPage': rowPerPage };
    getTransList(data);
  }  
  
  /*
    @function handle pagination
  */
  const handelPagination = (e, v, rows) => {
    setTableData(false);
    if (startDate) {
      var sDate = new Date(startDate);
      sDate.setDate(sDate.getDate() + 1);
      if (endDate === null || endDate === "" || endDate === undefined) { //Check end date was null,empty or undefined
        setEndDate(new Date());
        var eDate = new Date(new Date());
      } else {
        var eDate = new Date(endDate);
      }
      const data = { 'startDate': sDate.toISOString().slice(0, 10), 'endDate': eDate.toISOString().slice(0, 10), 'current_page': v, 'rowPerPage': rows };
      getTransList(data);
    } else {
      const data = { 'current_page': v, 'rowPerPage': rows };
      getTransList(data);
    }

  }

  /*
    @function for generate and export csv file of batch report
  */
  const csvLink = useRef();
  const handelExport = () => {
    var sDate = new Date(startDate);
    sDate.setDate(sDate.getDate() + 1);
    if (endDate === null || endDate === "" || endDate === undefined) { //Check end date was null,empty or undefined
      setEndDate(new Date());
      var eDate = new Date(new Date());
    } else {
      var eDate = new Date(endDate);
    }

    eDate.setDate(eDate.getDate() + 1);
    const data = { 'startDate': sDate.toISOString().slice(0, 10), 'endDate': eDate.toISOString().slice(0, 10), 'current_page': 1, 'rowPerPage': rowPerPage };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_export_batch', data, {
      headers: headers
    }).then(response => {

      const exportArr = [];
      exportArr.push(["Date", "Time", "Total", "Total Count", "Total Credit Amount", "Total Credit Transactions", "Total Debit Amount", "Total Debit Transactions", "Device"]);

      response.data.batchData.forEach((eachBatch) => {
        var cash_amt = "";
        var credit_amt = "";
        var debit_amt = "";
        cash_amt = Helper.viewCurrencyFormat(parseFloat(eachBatch.cash_amt));
        credit_amt = Helper.viewCurrencyFormat(parseFloat(eachBatch.credit_amt));
        debit_amt = Helper.viewCurrencyFormat(parseFloat(eachBatch.debit_amt));
        var total = parseFloat(eachBatch.credit_amt) + parseFloat(eachBatch.credit_tip_amt) + parseFloat(eachBatch.debit_amt) + parseFloat(eachBatch.debit_tip_amt) + parseFloat(eachBatch.ebt_amount);
        total = Helper.viewCurrencyFormat(parseFloat(total));
        var TotalCnt = parseInt(eachBatch.credit_cnt) + parseInt(eachBatch.debit_cnt);
        exportArr.push([eachBatch.device_date, eachBatch.device_time, total, TotalCnt, credit_amt, eachBatch.credit_cnt, debit_amt, eachBatch.debit_cnt, eachBatch.serial_nuber]);
      });
      setCsvData(exportArr);
      csvLink.current.link.click();
      setExportButtonLabel("Export");
    }).catch(error => {     
      return error;
    });
  }

  /*
    @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };  
  const handleCurrentBatch = () => {
    setCurrentBatchTransDailog(true);
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    };
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_current_batch', data, {
      headers: headers
    }).then(response => {

      var data = [];
      response.data.forEach((eachTrans) => {
        let transTime = (eachTrans.device_created_at) ? moment(new Date(eachTrans.device_created_at.replace(" ", "T"))).format('MM/DD/YYYY HH:mm') : "";
        data.push([transTime, eachTrans.serial_number, eachTrans.ecrref_num, <center>{(eachTrans.edc_type) ? eachTrans.edc_type : eachTrans.payment_method}</center>, <center>{eachTrans.auth_code}</center>, <div style={{ textAlign: 'right' }}>  {(eachTrans.amount) ? "$" : ""}  {(eachTrans.amount) ? eachTrans.amount : ""}</div>]);
      });
      setCurrentBatchTransData(data);
    }).catch(error => {     
      return error;
    });

  }
  if( accessPage === 0)
  { 
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Batch Report</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={4}></GridItem>
                <center>
                  <h3><div>You do not have permission to access this page.</div> <div>Please contact an administrator.</div><div>Thank you.</div></h3>
                  
                </center>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if table data not come from api than display loading progress bar
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    return (
      <GridContainer>
        {/* 
          Display error message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {errorMsg}
          </Alert>
        </Snackbar>

        {/* 
          Display success message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}> Batch Report</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading Batch...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }

  return (
    <GridContainer>
      {
        (csvData) ? <CSVLink data={csvData} ref={csvLink} target='_blank'></CSVLink> : ""
      }

      {/* Current Batch Trans Start*/}
      <Dialog
        open={currentBatchTransDailog}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ padding: "0px" }}
      >
        <DialogTitle id="alert-dialog-slide-title">Open Batch Transactions</DialogTitle>
        {
          (currentBatchTransData) ? <Table
            tableHeaderColor="primary"
            tableHead={["Trans. Time", "Serial Number", "ECR Ref#", "Payment Method", "Auth Code", <center>Amount</center>]}
            tableData={currentBatchTransData}
          /> : <center><CircularProgress /></center>
        }
        <DialogActions>
          <Button id="btnGreyCancel" onClick={(e) => { setCurrentBatchTransDailog(false); setCurrentBatchTransData(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Close
          </Button>

        </DialogActions>
      </Dialog>
      {/* Current Batch Trans End*/}

      {/* Delete Item  popup start*/}
      <Dialog
      id="dialoggggggg"
        open={batchTransDailog}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ padding: "0px" }}
      >
        <DialogTitle id="alert-dialog-slide-title">Batch Transactions List</DialogTitle>
        {
          (transData) ? <Table
            tableHeaderColor="primary"
            tableHead={["Trans. Time", "ECR Ref#", <center>Payment Method</center>, <center>Auth Code</center>, <center>Amount</center>]}
            tableData={transData}
          /> : <center><CircularProgress /></center>
        }

        <DialogActions>
          <Button id="btnGreyCancel"  onClick={(e) => { setBatchTransDailog(false); setTransData(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Close
          </Button>

        </DialogActions>
      </Dialog>
      {/* Delete Item  popup end*/}

      {/* Send email receipt start */}
      <Dialog open={openSendReceipt} onClose={() => { setOpenSendReceipt(false) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Send Email Receipt</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter Email Address"
            type="email"
            onChange={(e) => setSendEmail(e.target.value)}
            value={sendEmail}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setEcrRefNo(0); setSendEmail(""); setOpenSendReceipt(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Cancel
          </Button>
          <Button onClick={() => { sendEmailReceipt() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Send email receipt end */}

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}> Batch Report</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{marginTop:'20px'}}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="datetime-local"
                  label="Start Date"
                  style={{ width: '100%' }}
                  type="datetime-local"
                  value={startDate}
                  onChange={(event) => { setStartDate(event.target.value) }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{marginTop:'20px'}}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <TextField
                  id="datetime-local"
                  label="End Date"
                  type="datetime-local"
                  style={{ width: '100%' }}
                  value={endDate}
                  onChange={(event) => { setEndDate(event.target.value) }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={2} style={{ marginTop: "15px", marginLeft: "0px" }}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <Button variant="contained" color="primary" style={{backgroundColor: '#ffa726', color: '#FFFFFF',height: '55px' }} onClick={handleSearch} className={classes.button}>
                  Search
                </Button>
                </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={2} style={{ marginTop: "15px", marginLeft: "0px" }}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                <Button id="btnGreenAdd"  style={{ backgroundColor: '#ffa726', color: '#FFFFFF',height: '55px' }} onClick={() => { handelExport(); setExportButtonLabel(<CircularProgress style={{ color: 'white' }} id="loginProgress" ></CircularProgress>) }} variant="contained" color="primary" className={classes.button}>
                  {exportButtonLabel}
                </Button>
                <div style={{ clear: "both" }}></div>
                </FormControl>
              </GridItem>
            </GridContainer>
            <br />
            
            <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">Row per page</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={rowPerPage}
                  label="Row per page"
                  className={classes.rowPerPage}
                  onChange={(e) => { setRowPerPage(e.target.value); handelPagination(e, 1, e.target.value); }}
                >
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              </GridItem>
              </GridContainer>
  
            {/* 
              *Display batch report list
            */}
            <Table
              tableHeaderColor="primary"
              tableHead={[<div style={{ textAlign: "center" }}>Date</div>, <div style={{ textAlign: "center" }}>Time</div>, <div style={{ textAlign: "right" }}>Total</div>, <div style={{ textAlign: "center" }}>Device</div>, <div style={{ textAlign: "center" }}>View Batch Report</div>, "Status"]}
              tableData={tableData}
            />
          </CardBody>
          <div>
            <div style={{ float: "left" }}></div>
            <div style={{ float: "right", marginBottom: "10px", marginRight: "10px" }}>
              <Pagination onClick={addclass} className={classes.root} classes={{ ul: classes.ul }} style={{ color: "red" }} count={totalPages} defaultPage={currentPages} onChange={(e, v) => { handelPagination(e, v, rowPerPage); }} />
            </div>
          </div>

        </Card>

        <Dialog open={viewReportDailog} onBackdropClick={handledialogoutclick}>
          
          <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-slide-title">{"Batch Detailed Report"}</DialogTitle>
          <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
            <table style={{ width: "100%", cellpadding: "100" }}>
              <tr>
                <th colSpan={3} style={{ textAlign: "center" }}>Credit Tender</th>
              </tr>
              <tr>
                <td><b>Type</b></td>
                <td style={{ paddingRight: "20px", textAlign: 'right' }}><b>Count</b></td>
                <td style={{ textAlign: "right" }}><b>Amount</b></td>
              </tr>
              <tr>
                <td>Sales All</td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditAmt))}</td>
              </tr>
              <tr style={{ display: (creditVisaCount > 0) ? '' : 'none' }}>
                <td>Visa</td>
                <td style={{ textAlign: "center" }}>{creditVisaCount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditVisaAmt))}</td>
              </tr>
              <tr style={{ display: (creditMasterCardCount > 0) ? '' : 'none' }}>
                <td>MasterCard</td>
                <td style={{ textAlign: "center" }}>{creditMasterCardCount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditMasterCardAmt))}</td>
              </tr>
              <tr style={{ display: (creditDiscoverCount > 0) ? '' : 'none' }}>
                <td>Discover</td>
                <td style={{ textAlign: "center" }}>{creditDiscoverCount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditDiscoverAmt))}</td>
              </tr>
              <tr style={{ display: (creditAECount > 0) ? '' : 'none' }}>
                <td>American Express</td>
                <td style={{ textAlign: "center" }}>{creditAECount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditAEAmt))}</td>
              </tr>
              <tr style={{ display: (creditOtherCount > 0) ? '' : 'none' }}>
                <td>Other</td>
                <td style={{ textAlign: "center" }}>{creditOtherCount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditOtherAmt))}</td>
              </tr>
              <tr>
                <td>Void</td>
                <td style={{ textAlign: "center" }}>{creditVoidCnt}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditVoidAmt))}</td>
              </tr>
              <tr>
                <td>Returns</td>
                <td style={{ textAlign: "center" }}>{creditReturnCnt}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditReturnAmt))}</td>
              </tr>
              <tr>
                <td><b>Subtotal</b></td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}><b>{Helper.viewCurrencyFormat(parseFloat(creditSubTotal))}</b></td>
              </tr>
              <tr>
                <td>Credit Tips</td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditTipAmt))}</td>
              </tr>
              <tr>
                <td>Cash Discount Fee</td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditNonCashAdjAmt))}</td>
              </tr>
              <tr>
                <td><b>Credit Total</b></td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}><b>{Helper.viewCurrencyFormat(parseFloat(creditTotal))}</b></td>
              </tr>
            </table>
            <hr></hr>
            <table style={{ width: "100%" }}>
              <tr>
                <th colSpan={3} style={{ textAlign: "center" }}>Debit Tender</th>
              </tr>
              <tr>
                <td><b>Type</b></td>
                <td style={{ paddingRight: "20px" }}><b>Count</b></td>
                <td style={{ textAlign: "right" }}><b>Amount</b></td>
              </tr>
              <tr>
                <td>Sales All</td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitAmt))}</td>
              </tr>
              <tr style={{ display: (debitVisaCount > 0) ? '' : 'none' }}>
                <td>Visa</td>
                <td style={{ textAlign: "center" }}>{debitVisaCount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitVisaAmt))}</td>
              </tr>
              <tr style={{ display: (debitMasterCardCount > 0) ? '' : 'none' }}>
                <td>MasterCard</td>
                <td style={{ textAlign: "center" }}>{debitMasterCardCount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitMasterCardAmt))}</td>
              </tr>
              <tr style={{ display: (debitDiscoverCount > 0) ? '' : 'none' }}>
                <td>Discover</td>
                <td style={{ textAlign: "center" }}>{debitDiscoverCount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitDiscoverAmt))}</td>
              </tr>
              <tr style={{ display: (debitAECount > 0) ? '' : 'none' }}>
                <td>American Express</td>
                <td style={{ textAlign: "center" }}>{debitAECount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitAEAmt))}</td>
              </tr>
              <tr style={{ display: (debitOtherCount > 0) ? '' : 'none' }}>
                <td>Other</td>
                <td style={{ textAlign: "center" }}>{debitOtherCount}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitOtherAmt))}</td>
              </tr>
              <tr>
                <td>Void</td>
                <td style={{ textAlign: "center" }}>{debitVoidCnt}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitVoidAmt))}</td>
              </tr>
              <tr>
                <td>Returns</td>
                <td style={{ textAlign: "center" }}>{debitReturnCnt}</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitReturnAmt))}</td>
              </tr>
              <tr>
                <td><b>Subtotal</b></td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}><b>{Helper.viewCurrencyFormat(parseFloat(debitSubTotal))}</b></td>
              </tr>
              <tr>
                <td>Debit Tips</td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitTipAmt))}</td>
              </tr>
              <tr>
                <td>Cash Discount Fee</td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitNonCashAdjAmt))}</td>
              </tr>
              <tr>
                <td><b>Debit Total</b></td>
                <td style={{ textAlign: "center" }}></td>
                <td style={{ textAlign: "right" }}><b>{Helper.viewCurrencyFormat(parseFloat(debitTotal))}</b></td>
              </tr>
            </table>
            <hr></hr>
            {
              (ebtSalesAmt > 0 || ebtReturnsAmt > 0) ? <table style={{ width: "100%" }}>
                <tr>
                  <th colSpan={3} style={{ textAlign: "center" }}>EBT Tender</th>
                </tr>
                <tr>
                  <td>Sales</td>
                  <td>{ebtSalesCnt}</td>
                  <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(ebtSalesAmt))}</td>
                </tr>
                <tr>
                  <td>Returns</td>
                  <td>{ebtReturnsCnt}</td>
                  <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(ebtReturnsAmt)}</td>
                </tr>
                <tr>
                  <td><b>EBT Total</b></td>
                  <td></td>
                  <td style={{ textAlign: "right" }}><b>{Helper.viewCurrencyFormat(ebtTotal)}</b></td>
                </tr>
              </table> : ""
            }
            {(ebtSalesAmt > 0 || ebtReturnsAmt > 0) ? <hr></hr> : ""}

            <table style={{ width: "100%" }}>
              <tr>
                <th colSpan={2} style={{ textAlign: "center" }}>Summary</th>
              </tr>
              <tr>
                <td>Credit</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(creditSubTotal))}</td>
              </tr>
              <tr>
                <td>Debit</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitSubTotal))}</td>
              </tr>
              {(ebtSalesAmt > 0 || ebtReturnsAmt > 0) ? <tr>
                <td>EBT</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(ebtTotal))}</td>
              </tr> : ""}

              <tr>
                <td>Tips</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(totalTipAmt))}</td>
              </tr>
              
              <tr>
                <td>Cash Discount Fee</td>
                <td style={{ textAlign: "right" }}>{Helper.viewCurrencyFormat(parseFloat(debitNonCashAdjAmt) + parseFloat(creditNonCashAdjAmt)  )}</td>
              </tr>
              <tr>
                <td><b>Grand Total</b></td>
                <td style={{ textAlign: "right" }}><b>{Helper.viewCurrencyFormat(parseFloat(grandTotalAmt))}</b></td>
              </tr>
            </table>
          </div>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setViewReportDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </GridItem>
    </GridContainer>
  );
}

