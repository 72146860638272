/*
   @page table list page (merchant list)
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MUIDataTable from "mui-datatables";


/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function merchant list page logic
*/
const useStyles = makeStyles(styles);
const doLogin = (merchantId,locationId,isLocationActive) => {
  const data = { merchant_id: merchantId };
  const headers = {
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
  axios.post(window.apiUrl+'login_as_merchant', data, {
    headers: headers
  }).then(response => {
    if (response.data.token) {
      const currentWebUrl =  window.location.href;  
      var localHostMode = "OFF";
      if(currentWebUrl == "https://web16.magicpaypos.com/" || currentWebUrl == "https://web16.magicpaypos.com" || currentWebUrl == "https://web16.magicpaypos.com/login"  || currentWebUrl == "https://web16.magicpaypos.com/login/"){
            localHostMode = "ON"; 
      } else {
          if(currentWebUrl == "http://localhost:3000/admin/table" || currentWebUrl == "http://localhost:3000/admin/table/"){
              localHostMode = "ON";
          } else if(currentWebUrl == "https://devapp.magicpaypos.com/admin/table" || currentWebUrl == "https://devapp.magicpaypos.com/admin/table/"){
              localHostMode = "ON";
          } else if(currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/admin/table" || currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/admin/table/"){
              localHostMode = "ON";
          }
      }

      
      if(response.data.same_version === "0" && localHostMode == "OFF")
      { 
       
         window.open(response.data.react_url+'login/?arg1='+btoa(localStorage.getItem('arg1'))+'&arg2='+btoa(localStorage.getItem('arg2'))+'&arg3='+btoa(merchantId), '_blank');
      }
     else
      {      
        console.log("in elseeeee");  
        localStorage.setItem('is_active', isLocationActive);
        localStorage.setItem('merchantToken', response.data.token);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('parent_id', merchantId);
        localStorage.setItem('location_id',locationId);
        console.log("asdfadfadfasdf");
        window.open('/dashboard', '_blank');
        
      }

    }
  }).catch(error => {   
    return error;
  });

}

const options = {
  filterType: 'checkbox',
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  searchPlaceholder:'Search using MID, Business Name, Email',
  searchOpen: true,
  download:false,
  print:false,
  viewColumns:false,
  filter:false,
  searchAlwaysOpen:true,
  
};
export default function TableList() {
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [merchantStatus, setMerchantStatus] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [userId, setUserId] = useState(0);
  const [deleteDailog, setMerchantDeleteDailog] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [locationId, setLocationId] = useState(0);

  const [filterMId, setFilterMId] = useState("");
  const [filterBusinessName, setFilterBusinessName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [showVerfyConfirmBox, setShowVerifyConfirmBox] = useState(false);

  /*
    @function handle user active or deactive
  */
  const handleActiveUser = () => {
    setTableData(false);
    setShowConfirmBox(false);
    setSuccessMsg("");
    setSuccess(false);
    const data = { user_id: userId, is_active: (merchantStatus) ? 1 : 0 };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'update_staus', data, {
      headers: headers
    }).then(response => {
      if (merchantStatus) {
        setSuccessMsg("Merchant Activated Successfully!");
      } else {
        setSuccessMsg("Merchant Deactivated Successfully!");
      }
      getMerchantList();
      setSuccess(true);
    }).catch(error => {
      getMerchantList();
      setError(true);
      setErrorMsg("Some Error Occure!!");      
      return error;
    });


  }

  const handleVerfyTransSwitch = () => {    
    setTableData(false);
    setShowVerifyConfirmBox(false);
    setSuccessMsg("");
    setSuccess(false);
    const data = { location_id:locationId,merchant_id: userId, verify_batch_trans: (merchantStatus) ? 1 : 0 };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'save_setting', data, {
      headers: headers
    }).then(response => {
      setLocationId(0); setUserId(0); setMerchantStatus(false); 
      if (merchantStatus) {
        setSuccessMsg("Verify transaction ON Successfully!");
      } else {
        setSuccessMsg("Verify transaction OFF Successfully!");
      }
      getMerchantList();
      setSuccess(true);
    }).catch(error => {
      setLocationId(0); setUserId(0); setMerchantStatus(false);
      getMerchantList();
      setError(true);
      setErrorMsg("Some Error Occure!!");      
      return error;
    });


  }

  /*
    @Function get merchant list
  */  
  const getMerchantList = () => {
    const data = { filterBusinessName: filterBusinessName,filterEmail:filterEmail,filterMId:filterMId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'get_merchant_list', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.forEach((eachMerchant) => {

        console.log(eachMerchant);
        
        userArr.push([eachMerchant.id,eachMerchant.merchant_id,eachMerchant.business_name, eachMerchant.email, eachMerchant.created_at, <Button id="btnGreenAdd" color="warning" onClick={() => doLogin(eachMerchant.user_id,eachMerchant.location_id,eachMerchant.is_active)}>Login</Button>,
        <FormControlLabel
          control={<Switch checked={(eachMerchant.is_active == 1) ? true : false}
            onChange={(e) => { setShowConfirmBox(true); setUserId(eachMerchant.id); setLocationId(eachMerchant.location_id); setMerchantStatus(e.target.checked) }}
            name="checkedB" color="secondary" />}
          label={(eachMerchant.is_active == 1) ? "Active" : "Deactivated"} />,
          <FormControlLabel
          control={<Switch checked={(eachMerchant.verify_batch_trans == 1) ? true : false}
            onChange={(e) => { setShowVerifyConfirmBox(true); setLocationId(eachMerchant.location_id); setUserId(eachMerchant.id); setMerchantStatus(e.target.checked) }}
            name="checkedB" color="secondary" />}
          label={(eachMerchant.verify_batch_trans == 1) ? "On" : "Off"} />,
        <button id="reddeleteicon" style={{ backgroundColor: '#FF9800', color: '#FFFFFF', border: 'none' }} onClick={() => { setMerchantDeleteDailog(true); setUserId(eachMerchant.id); }} ><DeleteForeverIcon></DeleteForeverIcon></button>]);
      });
      setTableData(userArr);
    }).catch(error => {     
      return error;
    });
  }

  
  /*
    @Function for delete merchant
  */
  const handleDeleteMerchant = () => {
    const data = { user_id: userId,location_id:locationId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'delete_merchant', data, {
      headers: headers
    }).then(response => {
      setUserId(0);
      setMerchantDeleteDailog(false);
      if (response.data === 1) {
        setSuccessMsg("Merchant Deleted Successfully!");
        getMerchantList();
      } else {
        setSuccessMsg("Merchant Not Delete!");
      }
      setSuccess(true); 
    }).catch(error => {
      return error;
    });

  }
  
  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
    setSuccessMsg("");
  };

  useEffect(() => {
    getMerchantList();
  }, []);

  const columns = [
    {
     name: "ID",
     label: "ID",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "MID",
     label: "MID",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "Business Name",
     label: "Business Name",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "Email",
     label: "Email",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
      name: "Created at",
      label: "Created at",
      options: {
       filter: true,
       sort: false,
      }
     },
     {
      name: "Login As Merchant",
      label: "Login As Merchant",
      options: {
       filter: true,
       sort: false,
      }
     },
     {
      name: "Active/Deactivated",
      label: "Active/Deactivated",
      options: {
       filter: true,
       sort: false,
      }
     }
     ,
     {
      name: "Verify Transactions",
      label: "Verify Transactions",
      options: {
       filter: true,
       sort: false,
      }
     },
     {
      name: "Delete",
      label: "Delete",
      options: {
       filter: true,
       sort: false,
      }
     },
   ];

  if (!tableData) { //Check if table data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          {/* 
            Display error message
          */}
          
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Merchant List</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
             <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>

          {/* 
            Display success message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {successMsg}
            </Alert>
          </Snackbar>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Merchant List</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Ammount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label="MID"
                        value = { filterMId }
                        onChange={(e) => {setFilterMId(e.target.value)}}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Ammount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label="Business Name"
                        value = {filterBusinessName}
                        onChange={(e) => { setFilterBusinessName(e.target.value) }}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Ammount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label="Email"
                        value = { filterEmail}
                        onChange={(e) => { setFilterEmail(e.target.value) }}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                {/* <div style={{ height: "25px",display:"none" }}>&nbsp;</div> */}
                  <FormControl style={{ width: '100%',marginTop:"-5px",display:"none" }} className={classes.popupHalfWidth} xs={12} sm={12} md={3}>
                    <Button onClick = {getMerchantList}  variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} className={classes.button}>
                      Search
                    </Button>
                  </FormControl>
                </center>
              </GridItem>
            </GridContainer>
            
            {/* <Table
              tableHeadercolor="warning"
              tableHead={["ID","MID","Business Name", "Email", "Created at", "Login As Merchant", "Active/Deactivated", "Delete"]}
              tableData={tableData}
            /> */}
            <MUIDataTable
              id="newdatatable"
              // title={"Employee List"}
              data={tableData}
              columns={columns}
              options={options}
            />
          </CardBody>
        </Card>

        {/* 
          Delete activate or deactivate  popup start
        */}
        <Dialog
          open={showConfirmBox}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{(merchantStatus) ? "Are you sure you want to activate this loacation?" : "Are you sure you want to deactivate this loacation?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={() => { setUserId(0); setShowConfirmBox(false) }} color="warning">
              No
            </Button>
            <Button id="btnRedDelete" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={handleActiveUser} color="secondery">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* 
          Delete merchamt  popup start
        */}
        <Dialog
          open={showVerfyConfirmBox}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{(merchantStatus) ? "Are you sure you want to ON verify transaction?" : "Are you sure you want to OFF verify transaction?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={() => { setUserId(0); setShowVerifyConfirmBox(false);setMerchantStatus(false); setLocationId(0)}} color="warning">
              No
            </Button>
            <Button id="btnRedDelete" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={handleVerfyTransSwitch} color="secondery">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete this merchant?"}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setUserId(0); setMerchantDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              No
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteMerchant} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
