/*
   @page Device manager
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
/*
  @const Page styles
*/
const styles = {
  root: {
    '& .Mui-selected': {
      backgroundColor: 'black',
      color: '#ffa726',
    }
  },
  rowPerPage:
  {
    minHeight: "40px",
    height: "40px"
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#ffa726"
    }
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: 1,
    minWidth: 120,
    maxWidth: 300,
  },
  popupHalfWidth: {
    width: "100%",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 5,
  },
  setFieldHeight: {
    minHeight: "48px",
  },
};
function getStyles(category, categoryID, theme) {
  return {
    fontWeight:
      categoryID.indexOf(category) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


/*
  @function Display alert for error message
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
  @function Device manager page logic
*/
const useStyles = makeStyles(styles);
var seletedCheckBox = {};

export default function TableList() {
  /*
    variables start
  */
  const [tableData, setTableData] = useState(false);
  const [itemId, setItemId] = useState(0);
  const [deviceId, setDeviceId] = useState(0);
  const [deviceStatus, setDeviceStatus] = useState("");
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [openAddItem, setOpenAddItem] = React.useState(false);
  const [openEditItem, setOpenEditItem] = React.useState(false);
  const [nickname, setNickname] = React.useState("");
  const [serialNumber, setSerialNumber] = React.useState("");
  const [deviceType, setDeviceType] = React.useState("");
  const [showInPOS, setShowInPOS] = React.useState(true);
  const [merchant, setMerchant] = React.useState("");
  const [selectedMerchant, setSelectedMerchant] = React.useState([]);
  const [bussinessNameArr, setBussinessNameArr] = React.useState("");
  const [bussinessName, setBussinessName] = React.useState("");
  const [userIdArr, setUserIdArr] = React.useState("");
  const [selectedUserId, setSelectedUserId] = React.useState(0);
  const [deleteDeviceDialog, setDeviceDeleteDailog] = React.useState(false);
  const [clearDataDailog, setClearDataDailog] = React.useState(false);
  const [clearDataSerialNumber, setClearDataSerialNumber] = React.useState("");
  const [clearDataLocationId, setClearDataLocationId] = React.useState("");
  const [clearDataUserId, setClearDataUserId] = React.useState("");
  const [currentDeviceList, setCurrentDeviceList] = React.useState();
  const [merchantSerialNumber, setMerchantSerialNumber] = React.useState();
  const [tokonizationEnable, setTokonizationEnable] = React.useState("");
  const [confirmBoxMSg, setConfirmBoxMSg] = React.useState();
  const [rowPerPage, setRowPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(10);
  const [currentPages, setCurrentPages] = useState(1);

  const [search_business_name, setSearchBussinessName] = useState("");
  const [search_serial_number, setSearchSerialNumber] = useState("");
  const [search_version, setSearchVersion] = useState("");
  const [search_device_type, setSearchDeviceType] = useState("");
  const [locationIdArr, setSetLocationIdArr] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState(0);

  const [openEditDeviceDialog, setEditDeviceDailog] = useState(false);
  const [versionDropDownVal, setVersionDropDownVal] = useState([]);
  const [chkversionVal, setChkversionVal] = useState([]);

  const addclass = () => {
    document.querySelector(".ps--active-y").classList.remove('newTop');
    document.querySelector(".ps--active-y").classList.add('newTop');
    setTimeout(function () {
      document.querySelector(".ps--active-y").classList.remove('newTop');
    }, 2000);
  }


  /*
    * Get merchant list
  */
  const getMerchantList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'get_merchant_list', data, {
      headers: headers
    }).then(response => {
      const bussinessNameArray = new Object();
      const userIds = new Object();
      var merchantName = [];
      var locArr = [];
      var serialNumberBussinesName = [];
      response.data.forEach((eachMerchant) => {
        const merchantNameObj = new Object();
        merchantNameObj['label'] = eachMerchant.merchant_id;
        merchantNameObj['merchant_id'] = eachMerchant.name;
        merchantNameObj['userId'] = eachMerchant.user_id;
        merchantName.push(merchantNameObj);
        serialNumberBussinesName[eachMerchant.user_id] = eachMerchant.business_name;
        bussinessNameArray[eachMerchant.merchant_id] = eachMerchant.business_name;
        userIds[eachMerchant.merchant_id] = eachMerchant.user_id;
        locArr[eachMerchant.merchant_id] = eachMerchant.location_id;

      });
      setMerchant(merchantName);
      setBussinessNameArr(bussinessNameArray);
      setUserIdArr(userIds);
      setMerchantSerialNumber(serialNumberBussinesName);
      setSetLocationIdArr(locArr);

    }).catch(error => {
      return error;
    });
  }


  /*
    *Clear all variables value
  */
  const clearVal = () => {
    setItemId("");
    setNickname("");
    setDeviceType("");
    setSerialNumber("");
    setSelectedMerchant("");
  }


  /*
    *Function for validation
  */
  const validateVal = () => {
    var errorMessageString = '';

    if (serialNumber === '' || serialNumber === null) //Check if serial number was blank or null
    {
      errorMessageString += 'Please Enter Serial Number.\n';
    } else {
      if (currentDeviceList[serialNumber]) //Check id device has serial number
      {
        errorMessageString += "Device is already being used by " + merchantSerialNumber[currentDeviceList[serialNumber]];
      }
    }

    if (deviceType.toString() === '' || deviceType.toString() === null) //Check id device type was null or blank
      errorMessageString += 'Please Select Device Type.\n';

    if (nickname.toString() === '' || nickname.toString() === null) //Check nick name was null or blank
    errorMessageString += 'Please Enter Nick Name.\n';

    if (selectedUserId === 0 ) //Check merchant was null or blank
    errorMessageString += 'Please Select merchant.\n';

    

    setErrorMsg(errorMessageString);
    if (errorMessageString === '') { //Check error message was blank
      setError(false);
      return true;
    }
    else {
      setError(true);
      return false;
    }
  }


  /*
    *Function for get device list
  */
  var uniqueVersionNo = [];
  const getDeviceList = (data) => {

    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'get_device_list', data, {
      headers: headers
    }).then(response => {
      var x = [];

      var merchantNameStr = '';
      const userArr = [];
      setTotalPages(response.data.totalPage);
      setCurrentPages(response.data.current_page);
      console.log("ververververver", response);
      response.data.allDeviceData.forEach((eachDevice) => {
        x[eachDevice.serial_number] = eachDevice.merchant_id;
      });
      response.data.deviceData.forEach((eachDevice) => {
        if (eachDevice.business_name == null) //Checl if merchant name was null
        {
          merchantNameStr = "-";
        } else {
          merchantNameStr = eachDevice.business_name;
        }
        userArr.push([merchantNameStr, eachDevice.serial_number, eachDevice.nick_name, eachDevice.device_type,
          <FormControlLabel
            control={<Switch checked={(eachDevice.is_approve == 1) ? true : false} //if device was approved than switch on else switch off
              onChange={(e) => { setShowConfirmBox(true); setDeviceId(eachDevice.id); setDeviceStatus(e.target.checked); setConfirmBoxMSg((e.target.checked) ? "Are you sure you want to activate this device?" : "Are you sure you want to deactivate this device?"); }}
              name="checkedB" color="secondary" />}
            label={(eachDevice.is_approve == 1) ? "Active" : "Deactivated"} />,//if device was approved than activated else deactivated     
          <FormControlLabel
            control={<Switch checked={(eachDevice.tokenization_enable == 1) ? true : false}
              onChange={(e) => { setShowConfirmBox(true); setDeviceId(eachDevice.id); setTokonizationEnable(e.target.checked); setConfirmBoxMSg((e.target.checked) ? "Are you sure you want to enable tokenization for this device?" : "Are you sure you want to disable tokenization for this device?"); }}
              name="checkedB" color="secondary" />}
            label={(eachDevice.tokenization_enable == 1) ? "Enable" : "Disable"} />,
          (eachDevice.last_login_at) ? moment(new Date(eachDevice.last_login_at.replace(" ", "T"))).format('MM/DD/YYYY hh:mm A') : "",
          eachDevice.version,
          <button id="reddeleteicon" style={{ backgroundColor: '#FF9800', color: '#FFFFFF', border: 'none' }} onClick={() => { setDeviceDeleteDailog(true); setDeviceId(eachDevice.id); }} ><DeleteForeverIcon></DeleteForeverIcon></button>,
          <span onClick={() => { setClearDataDailog(true); setClearDataSerialNumber(eachDevice.serial_number); setClearDataLocationId(eachDevice.location_id); setClearDataUserId(eachDevice.merchant_id); }} ><InstallMobileIcon id="installBtn"></InstallMobileIcon ></span>,
          <button id="greenediticon" onClick={() => { setDeviceId(eachDevice.id), setNickname(eachDevice.nick_name), setDeviceType(eachDevice.device_type), setEditDeviceDailog(true) }} style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }}><EditIcon style={{ height: '20px' }}></EditIcon></button>
        ]);
      });
      setCurrentDeviceList(x);
      setTableData(userArr);

      setVersionDropDownVal(response.data.allVersions);

      response.data.allDeviceData.forEach((eachDevice) => {
        //if(eachDevice.version != '' && eachDevice.version != 'NULL' && eachDevice.version != 'null'){
        uniqueVersionNo[eachDevice.version] = eachDevice.version;
        //}
        // x[eachDevice.version] = eachDevice.merchant_id;  
      });

      console.log("***************");
      console.log(uniqueVersionNo);
      console.log("***************");


      //setChkversionVal();


      // const setData = new Set(response.data.allDeviceData.version);



    }).catch(error => {
      return error;
    });
  }

  /*
    @function handle acticate device
  */
  const handleActiveDevice = () => {
    setTableData(false);
    setShowConfirmBox(false);
    setSuccessMsg("");
    setSuccess(false);

    var formData = new FormData();
    formData.append("id", deviceId);

    if (deviceStatus !== "") {

      formData.append("is_active", (deviceStatus) ? 1 : 0);

    }
    console.log("222222*" + tokonizationEnable);
    if (tokonizationEnable !== "") {
      formData.append("tokenization_enable", (tokonizationEnable) ? 1 : 0);

    }
    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1]);
    }

    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'update_device_staus', formData, {
      headers: headers
    }).then(response => {
      setTokonizationEnable("");
      setDeviceStatus("");
      if (deviceStatus) { //Check id device status was 1 than device activated else deactivated
        setSuccessMsg("Changes Saved Successfully!");
      } else {
        setSuccessMsg("Changes Saved Successfully!");
      }
      const data = { 'current_page': 1, 'rowPerPage': rowPerPage, 'serial_number': search_serial_number, 'business_name': search_business_name, 'device_type': search_device_type, 'version': search_version };
      getDeviceList(data);
      setSuccess(true);
    }).catch(error => {
      const data = { 'current_page': 1, 'rowPerPage': rowPerPage, 'serial_number': search_serial_number, 'business_name': search_business_name, 'device_type': search_device_type, 'version': search_version };
      getDeviceList(data);
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });
  }

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };



  const handleEditDeviceData = () => {

    if (nickname == "" || nickname == null) {
      setError(true);
      setErrorMsg("Please Enter Valid Nick Name!");
    }
    else if (deviceType == "" || deviceType == null) {
      setError(true);
      setErrorMsg("Please Select Valid Device Type!");
    }
    else {
      const data = {
        id: deviceId,
        nick_name: nickname,
        device_type: deviceType,
      };
      setEditDeviceDailog(false);
      setTableData(false);
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
      data.parent_id = localStorage.getItem('parent_id');
      data.location_id = localStorage.getItem('location_id');
      axios.post(window.apiUrl + 'update_nickname', data, {
        headers: headers
      }).then(response => {
        const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
        getDeviceList(data);
        setSuccess(true);
        setSuccessMsg("Nickname Updated Successfully!");
        clearVal();
      }).catch(error => {
        const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
        getDeviceList(data);
        setError(true);
        setErrorMsg("Some Error Occure!!");
        return error;
      });
    }
  }

  /*
   @function save device
  */
  const saveDevice = () => {
    if (validateVal() === true) {
      const data = {
        merchant_id: selectedUserId,
        nick_name: (nickname === "") ? "-" : nickname,
        serial_number: serialNumber,
        device_type: deviceType,
        location_id: selectedLocationId

      };
      setOpenAddItem(false);
      setTableData(false);
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
      axios.post(window.apiUrl + 'save_device', data, {
        headers: headers
      }).then(response => {
        clearVal();
        const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
        getDeviceList(data);
        setSuccess(true);
        setSuccessMsg("Device Added Successfully!");
      }).catch(error => {
        const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
        getDeviceList(data);
        setError(true);
        setErrorMsg("Some Error Occure!!");
        return error;
      });
    }

  }

  const handleVersionChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearchVersion(value);
  };

  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
    getMerchantList();
    getDeviceList(data);

  }, []);

  /*
   @function delete device
  */
  const handleDeleteDailog = () => {
    setTableData(false);
    setDeviceDeleteDailog(false);
    setSuccessMsg("");
    setSuccess(false);
    const data = { id: deviceId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'delete_device', data, {
      headers: headers
    }).then(response => {
      setDeviceId(0);

      if (response.data === 1) {
        setSuccess(true);
        setSuccessMsg("Device Deleted Successfully!");
        const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
        getDeviceList(data);
      } else {
        setError(true);
        setErrorMsg("Some Error Occure!!");
        const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
        getDeviceList(data);
      }
      setSuccess(true);
    }).catch(error => {
      return error;
    });

  }
  const handelPagination = (e, v, rows) => {
    setTableData(false);
    if (false) {
      var sDate = new Date(startDate);
      if (endDate === null || endDate === "" || endDate === undefined) {
        setEndDate(new Date());
        var eDate = new Date(new Date());
      } else {
        var eDate = new Date(endDate);
      }
      const data = { 'startDate': sDate.toISOString().slice(0, 10), 'endDate': eDate.toISOString().slice(0, 10), 'current_page': v, 'rowPerPage': rows, 'filterAmtFrom': filterAmtFrom, 'filterAmtTo': filterAmtTo, 'filterTenderType': filterTenderType, 'filtercardType': filtercardType, 'filterEmp': filterEmp, 'filterDevice': filterDevice, 'filterTransactionType': filterTransactionType, 'filterPaymentRes': filterPaymentRes };
      getDeviceList(data);
    } else {
      const data = { 'current_page': v, 'rowPerPage': rows, 'serial_number': search_serial_number, 'business_name': search_business_name, 'device_type': search_device_type, 'version': search_version };
      getDeviceList(data);
    }

  }
  const handleClearDataDailog = () => {
    var formData = new FormData();
    formData.append("serial_number", clearDataSerialNumber);
    formData.append("location_id", clearDataLocationId);

    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'reset_local_database', formData, {
      headers: headers
    }).then(response => {
      setClearDataDailog(false);
      setSuccessMsg("Database Clear And Download new Data Started");
      setSuccess(true);
    }).catch(error => {
      setClearDataDailog(false);
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });
  }
  const handleSearch = () => {

    // const data = { 'current_page': 1, 'rowPerPage': rowPerPage };
    const data = { 'current_page': 1, 'rowPerPage': rowPerPage, 'serial_number': search_serial_number, 'business_name': search_business_name, 'device_type': search_device_type, 'version': search_version };
    console.log(data);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'get_device_list', data, {
      headers: headers
    }).then(response => {

      var x = [];
      var merchantNameStr = '';
      const userArr = [];
      setTotalPages(response.data.totalPage);
      setCurrentPages(response.data.current_page);
      response.data.deviceData.forEach((eachDevice) => {
        if (eachDevice.business_name == null) //Checl if merchant name was null
        {
          merchantNameStr = "-";
        } else {
          merchantNameStr = eachDevice.business_name;
        }
        x[eachDevice.serial_number] = eachDevice.merchant_id;
        userArr.push([merchantNameStr, eachDevice.serial_number, eachDevice.nick_name, eachDevice.device_type,
          <FormControlLabel
            control={<Switch checked={(eachDevice.is_approve == 1) ? true : false} //if device was approved than switch on else switch off
              onChange={(e) => { setShowConfirmBox(true); setDeviceId(eachDevice.id); setDeviceStatus(e.target.checked); setConfirmBoxMSg((e.target.checked) ? "Are you sure you want to activate this device?" : "Are you sure you want to deactivate this device?"); }}
              name="checkedB" color="secondary" />}
            label={(eachDevice.is_approve == 1) ? "Active" : "Deactivated"} />,//if device was approved than activated else deactivated  
          <FormControlLabel
            control={<Switch checked={(eachDevice.tokenization_enable == 1) ? true : false}
              onChange={(e) => { setShowConfirmBox(true); setDeviceId(eachDevice.id); setTokonizationEnable(e.target.checked); setConfirmBoxMSg((e.target.checked) ? "Are you sure you want to enable tokenization for this device?" : "Are you sure you want to disable tokenization for this device?"); }}
              name="checkedB" color="secondary" />}
            label={(eachDevice.tokenization_enable == 1) ? "Enable" : "Disable"} />,
          (eachDevice.last_login_at) ? moment(new Date(eachDevice.last_login_at.replace(" ", "T"))).format('MM/DD/YYYY hh:mm A') : "",
          eachDevice.version,
          <button id="reddeleteicon" style={{ backgroundColor: '#FF9800', color: '#FFFFFF', border: 'none' }} onClick={() => { setDeviceDeleteDailog(true); setDeviceId(eachDevice.id); }} ><DeleteForeverIcon></DeleteForeverIcon></button>,
          <span onClick={() => { setClearDataDailog(true); setClearDataSerialNumber(eachDevice.serial_number); setClearDataLocationId(eachDevice.location_id); setClearDataUserId(eachDevice.merchant_id); }} ><InstallMobileIcon id="installBtn"></InstallMobileIcon ></span>,
          <button id="greenediticon" onClick={() => { setDeviceId(eachDevice.id), setNickname(eachDevice.nick_name), setDeviceType(eachDevice.device_type), setEditDeviceDailog(true) }} style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }}><EditIcon style={{ height: '20px' }}></EditIcon></button>
        ]);

      });
      setCurrentDeviceList(x);
      setTableData(userArr);
    }).catch(error => {
      const data = { 'current_page': 1, 'rowPerPage': rowPerPage, 'serial_number': search_serial_number, 'business_name': search_business_name, 'device_type': search_device_type };
      getDeviceList(data);
      setError(true);
      setErrorMsg("Some Error Occure!!");
      return error;
    });

  }

  if (!tableData) { //Check if device data not come than display loading dialog
    return (
      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Device List</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>Loading...</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
      {/* 
        Show confrm box start
      */}
      <Dialog
        open={showConfirmBox}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >

        {/* <DialogTitle id="alert-dialog-slide-title">{(deviceStatus) ? "Are you sure you want to activate this device?" : "Are you sure you want to deactivate this device?"}</DialogTitle> */}
        <DialogTitle id="alert-dialog-slide-title">{confirmBoxMSg}</DialogTitle>
        <DialogActions>
          <Button id="btnGreyCancel" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={() => { setDeviceId(0); setShowConfirmBox(false); setTokonizationEnable(""); setDeviceStatus(""); }} color="warning">
            No
          </Button>
          <Button id="btnRedDelete" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }} onClick={handleActiveDevice} color="secondery">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* 
          * Edit Device form start 
        */}
      <Dialog
        open={openEditDeviceDialog} onClose={() => { setEditDeviceDailog(false) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Device</DialogTitle>
        <DialogContent>

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={["Pax A60", "Pax A77", "Pax A80", "Pax A800", "Pax A920", "Pax A920Pro", "Pax Aries 6", "Pax Aries 8", "Pax E600", "Pax E700", "Pax E800", "ValorPayTech VL100", "ValorPayTech VL110", "ValorPayTech VL300", "ValorPayTech VL500"]}
            onSelect={(event) => { setDeviceType(event.target.value) }}
            value={deviceType}
            renderInput={(params, option) => <TextField {...params} label="Device Type" />}

          />

          <TextField
            margin="dense"
            id="name"
            label="Device Nick Name"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            fullWidth
          />

        </DialogContent>
        <DialogActions>
          <Button id="btnGreyCancel" onClick={() => { setEditDeviceDailog(false),clearVal(); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Cancel
          </Button>
          <Button id="btnGreenEdit" onClick={() => { handleEditDeviceData() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* 
        Delete Device  popup start
       */}
      <Dialog
        open={deleteDeviceDialog}
        keepMounted
        onClose={deleteDeviceDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want to delete this Device?"}</DialogTitle>
        <DialogActions>
          <Button id="btnGreyCancel" onClick={() => { setDeviceId(0); setDeviceDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            No
          </Button>
          <Button id="btnRedDelete" onClick={handleDeleteDailog} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* 
        Clear Device  data popup start
       */}
      <Dialog
        open={clearDataDailog}
        keepMounted
        // onClose={setClearDataDailog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogTitle id="alert-dialog-slide-title">{"Are you sure you want clear this device local database and insert new data?"}</DialogTitle>
        <DialogActions>
          <Button id="btnGreyCancel" onClick={() => { setClearDataDailog(false); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            No
          </Button>
          <Button id="btnRedDelete" onClick={handleClearDataDailog} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* Clear Device  data popup start */}
      <GridItem xs={12} sm={12} md={12}>

        {/* 
            Display error message
          */}
        <Snackbar id="popuperror" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" style={{ whiteSpace: 'pre-line' }}>
            {errorMsg}
          </Alert>
        </Snackbar>

        {/* 
            Display success message
          */}
        <Snackbar  id="popupsuccess" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Device List</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <Button
              id="btnGreenAdd"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
              onClick={() => { setOpenAddItem(true) }}
            >+</Button>
            <GridContainer>
              {/* Select start date and end date */}

              <GridItem xs={12} sm={12} md={3}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }}>
                  {/* Ammount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label="Business Name"
                    onChange={(e) => { setSearchBussinessName(e.target.value) }}
                    value={search_business_name}

                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} >
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }}>
                  {/* Ammount to textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Serial Number"
                    onChange={(e) => { setSearchSerialNumber(e.target.value) }}
                    value={search_serial_number}

                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} >
                <div style={{ height: "17px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }} sx={{ m: 1, width: "100%" }}>
                  {/* Ammount to textfield */}
                  <InputLabel id="demo-simple-select-helper-label">Device Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select"
                    label="Device Type"
                    style={{ width: '100%' }}
                    value={search_device_type}
                    onChange={(e) => setSearchDeviceType(e.target.value)}
                    input={<OutlinedInput label="Device Type" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"Pax A60"}>Pax A60</MenuItem>
                    <MenuItem value={"Pax A77"}>Pax A77</MenuItem>
                    <MenuItem value={"Pax A80"}>Pax A80</MenuItem>
                    <MenuItem value={"Pax A800"}>Pax A800</MenuItem>
                    <MenuItem value={"Pax A920"}>Pax A920</MenuItem>
                    <MenuItem value={"Pax A920Pro"}>Pax A920Pro</MenuItem>
                    <MenuItem value={"Pax Aries 6"}>Pax Aries 6</MenuItem>
                    <MenuItem value={"Pax Aries 8"}>Pax Aries 8</MenuItem>
                    <MenuItem value={"Pax E600"}>Pax E600</MenuItem>
                    <MenuItem value={"Pax E700"}>Pax E700</MenuItem>
                    <MenuItem value={"Pax E800"}>Pax E800</MenuItem>
                    <MenuItem value={"ValorPayTech VL100"}>ValorPayTech VL100</MenuItem>
                    <MenuItem value={"ValorPayTech VL110"}>ValorPayTech VL110</MenuItem>
                    <MenuItem value={"ValorPayTech VL300"}>ValorPayTech VL300</MenuItem>
                    <MenuItem value={"ValorPayTech VL500"}>ValorPayTech VL500</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">Version</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select"
                    label="Version"
                    style={{ width: '100%' }}
                    value={search_version}
                    onChange={handleVersionChange}
                    input={<OutlinedInput label="Version" />}
                    MenuProps={MenuProps}
                  >

                    {

                      versionDropDownVal.map((eachVersion) => {

                        if (eachVersion.version != null && eachVersion.version != "") {
                          return <MenuItem key={eachVersion.version} value={eachVersion.version}>
                            {eachVersion.version}
                          </MenuItem>;
                        }
                      })}
                  </Select>
                </FormControl>
              </GridItem>

            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>

              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                  <div style={{ height: "25px" }}>&nbsp;</div>
                  <FormControl style={{ with: '100%' }} className={classes.popupHalfWidth} xs={12} sm={12} md={12}>
                    <Button variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} onClick={handleSearch} className={classes.button}>
                      Search
                    </Button>
                  </FormControl>
                </center>

              </GridItem>
            </GridContainer>
            <div id="deviceListtable" class="deviceListtable">
              <Table
                tableHeaderColor="primary"
                tableHead={["Business Name", "Serial Number", "Nickname", "Device Type", "Active/Deactivated", "Token", "Last Login", "Version", "Delete", "Clear Data", "Edit"]}
                tableData={tableData}
              />
            </div>
            <div className="divDmRowPerPage">
              <div className="divSpinnerRow" style={{ float: "left" }}>
                <FormControl sx={{ m: 1, minWidth: 120, minHeight: 40 }}>

                  {/* Dropdown for select how many transaction list show in single page */}
                  <InputLabel id="demo-simple-select-helper-label">Rows per page</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={rowPerPage}
                    label="Row per page"
                    className={classes.rowPerPage}
                    onClick={addclass}
                    onChange={(e) => { setRowPerPage(e.target.value); handelPagination(e, 1, e.target.value); }}
                  >
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ float: "right", marginBottom: "10px", marginRight: "10px" }}>
                <Pagination onClick={addclass} className={classes.root} classes={{ ul: classes.ul }} style={{ color: "red" }} count={(totalPages == 0) ? 1 : totalPages} defaultPage={currentPages} onChange={(e, v) => { handelPagination(e, v, rowPerPage); }} />
              </div>
            </div>
          </CardBody>
        </Card>


        {/* 
          Add Item form start 
        */}
        <Dialog open={openAddItem} onClose={() => { setOpenAddItem(false); }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add New Device</DialogTitle>
          <DialogContent>


            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={merchant}
              onSelect={(event) => { setSelectedLocationId(locationIdArr[event.target.value]); setSelectedMerchant(event.target.value); setBussinessName(bussinessNameArr[event.target.value]); setSelectedUserId(userIdArr[event.target.value]); }}
              renderInput={(params, option) => <TextField {...params} label="Select Merchant" />}
              sx={{ width: 230 }}
            />


            <div style={{ display: (bussinessName == "") ? 'none' : 'block' }}>
              <TextField
                autoFocus
                margin="dense"
                id="business_name"
                label="Business Name"
                value={bussinessName}
                fullWidth
                disabled
              />
            </div>

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Serial Number"
              onChange={(e) => setSerialNumber(e.target.value)}
              value={serialNumber}
              fullWidth
            />


            <div style={{ clear: 'both' }}></div>




            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={["Pax A60", "Pax A77", "Pax A80", "Pax A800", "Pax A920", "Pax A920Pro", "Pax Aries 6", "Pax Aries 8", "Pax E600", "Pax E700", "Pax E800", "ValorPayTech VL100", "ValorPayTech VL110", "ValorPayTech VL300", "ValorPayTech VL500"]}
              onSelect={(event) => { setDeviceType(event.target.value) }}
              renderInput={(params, option) => <TextField {...params} label="Device Type" />}

            />


            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nickname"
              onChange={(e) => setNickname(e.target.value)}
              value={nickname}
              fullWidth
            />

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setOpenAddItem(false); clearVal(); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Cancel
            </Button>
            <Button id="btnGreenAdd" onClick={() => { saveDevice() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

      </GridItem>
    </GridContainer>
  );
}
